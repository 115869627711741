<template>
    <div class="QingContainer" v-loading="loading">
        <div class="bread">
            <el-breadcrumb separator="/" style="margin-bottom: 5px;" v-if="content.categoriesList&&content.categoriesList.length&&content.categoriesList.length>1">
                <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread" :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item, index) in content.categoriesList" :key="index" :to="breadCrumbTo_3(item,index)">{{item.name}}</el-breadcrumb-item>
                <el-breadcrumb-item >{{content.shortTitle}}</el-breadcrumb-item>
            </el-breadcrumb>
             <el-breadcrumb separator="/" style="margin-bottom: 5px;" v-else>
                <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread" :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item >{{content.shortTitle}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="title" v-show="this.categoryId!=77">{{content.shortTitle}}</div>
        <hr class="hrClass" v-show="this.categoryId!=77">
        
        <div  v-if="content.contentType&&content.contentType=='markdown'" :class="this.categoryId==77?'overviewContent moduleContent':'detail_overviewContent moduleContent'">
           <v-md-editor v-model="content.content"  mode="preview" />
        </div>
        <div v-else :class="this.categoryId==77?'overviewContent moduleContent':'detail_overviewContent moduleContent'"  v-html="content.content"></div>
        
        
        <div class="buttonDiv" v-show="contentExist">
            <el-button @click="getMoreDetail()" type="primary" class="btn-danger">更多资料下载</el-button>
            <el-button @click="getMoreDemo()" type="primary" class="btn-primary">样品获取</el-button>
            <el-button @click="getContact()" class="btn-info">联系我们</el-button>
        </div>
        <div v-if="filesList.length > 0">
            <div class="moduleTitle">相关资料</div>
            <el-row :gutter="0" style="width:100%" class="table_1">
              <el-col :xs="8" :sm="7" :md="6" :lg="5" :xl="4" class="tableTitle">资料名称</el-col>
              <el-col :xs="16" :sm="17" :md="18" :lg="19" :xl="20"  class="tableContent">资料简介</el-col>
            </el-row>
            <div v-for="(item,index) in filesList" :key="index" class="table_2">
                <el-row :gutter="0" style="width:100%" >
                    <el-col :xs="8" :sm="7" :md="6" :lg="5" :xl="4" v-if="item" class="tableTitle">
                        <a class="tableTitle" :href="getLickStr(item.name)" target="_blank">{{ item.name }}</a>
                    </el-col>
                    <el-col :xs="16" :sm="17" :md="18" :lg="19" :xl="20"  v-if="item" class="tableContent">{{ item.content }}</el-col>
                </el-row>
            </div>
        </div>

        <!-- 产品没有详情页 -->
        <div v-show="!contentExist" style="display: flex; justify-content: center; min-height:600px; align-items: center">
            <img src="@/assets/image/noData.png" alt="">
        </div>
    </div>
</template>
<script>
    import { getArticle } from '@/services/product.js'
    import VueMarkdown from 'vue-markdown'
    import VueMarkdownEditor, { xss } from '@kangc/v-md-editor'
    export default {
    name: "Qinke",
    components: {
       VueMarkdown,
       VueMarkdownEditor,
    },
    data() {
        return {
            content:'',
            filesList:'',
            url:{
                contentUrl:'/api/official/website/articles/getArticle'
            },
            loading: false,
            categoryId:'',
            contentExist:true,//存在文章
        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    activated(){
        if(this.$route.query.categoryId){
            this.categoryId = this.$route.query.categoryId
            this.$emit('giveCategoryId',this.categoryId)//将当前激活侧边菜单传递给父组件
        }
        this.getContent_1()
    },
    methods:{
        //获取页面内容
        getContent_1(){
            this.loading = true;
            console.log('222', this.$route.params.productName)
            console.debug(this.$route.params.productName)
            let p = this.$route.params.productName
            if(p.indexOf('.html')<0){
                p=p+'.html'
            }
            getArticle({alias:p}).then(res => {
                if(res.data.code===0 && res.data){
                    this.content = res.data.data
                    this.content.shortTitle = this.content.shortTitle.replace(/:/g,' ')
                    this.filesList = this.content.filesList

                    document.title = this.content.shortTitle+window._CONFIG['pageTitle']
                    this.contentExist = true
                }else{
                    // this.$message.error('该产品暂无详情页面！');
                    this.contentExist = false
                }
                this.loading = false;
            });
        },
        clickToTab(str){
            str = str.replace(".", "_")
            window.open('https://www.wch.cn/downloads/'+str+'.html')
        },
        getLickStr(str){
            str = str.replace(".", "_")
            return 'https://www.wch.cn/downloads/'+str+'.html'
        },
        getMoreDetail(){
            window.open('https://www.wch.cn/search.html?q='+this.content.alias+'&t=downloads', '_self') 
        },
        getMoreDemo(){
            window.open('https://www.wch.cn/services/request_sample.html?product_name='+this.content.alias, '_self')
        },
        getContact(){
            window.open('https://www.wch.cn/contact_us.html')
        },

        breadCrumbTo_3(item,index){
            if (index == 3) {
                if(this.content.categoriesList[1].id==69){//其他芯片 的tab卡
                    return  { path: '/products/productsCenter/otherChip', query:{categoryId:this.content.categoriesList[1].id,tName:item.name} }
                }else{//常规
                    return  { path: '/products/productsCenter/mcuInterface', query:{categoryId:this.content.categoriesList[1].id, tName:item.name} }
                }
            }else if(index==2){//长度为3:最后一个为tab卡
                this.$emit('giveCategoryId',this.content.categoriesList[1].id)//将当前激活侧边菜单传递给父组件
                if(this.content.categoriesList[1].id==69){//其他芯片 的tab卡
                  return  { path: '/products/productsCenter/otherChip', query:{categoryId:this.content.categoriesList[1].id,tName:item.name} }
                }else{//常规
                  return  { path: '/products/productsCenter/mcuInterface', query:{categoryId:this.content.categoriesList[1].id, tName:item.name} }
                }
            }else{//长度为2
                if(this.content.alias=='QingKe'){//青稞
                   this.$emit('giveCategoryId',77)//将青稞 传递给父组件
                }else{//其他
                   this.$emit('giveCategoryId',this.content.categoriesList[1].id)//将当前激活侧边菜单传递给父组件
                }
                
                if(item.id==5||item.id==68){
                    return  { path: '/products/productsCenter', query:{categoryId:item.id} }
                }
                else if(item.id==69){
                    return  { path: '/products/productsCenter/otherChip', query:{categoryId:item.id} }
                }
                else{
                    return  { path: '/products/productsCenter/mcuInterface', query:{categoryId:item.id} }
                }
            }
        },
    }
};
</script>
<style scoped>

.hrClass{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #cbd0d4;
}



.detail_overviewContent{
    font-size: 15px;
}
.detail_overviewContent /deep/a{
    color: #0070c0 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/span{
    font-size: 15px !important;
    /* color: #000 !important; */
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ h4 span {
    font-size: 20px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ h5 span, 
.detail_overviewContent /deep/ h5  {
    font-weight: 700;
    color: #00B0F0 !important;
    padding-left: 5px;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    margin: 0px;
}
.detail_overviewContent /deep/ span a {
    font-size: 15px !important;
}
.detail_overviewContent /deep/ p span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}

.detail_overviewContent /deep/ ul li span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ ol li span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ ol li a span {
    font-size: 15px !important;
    color: #0070c0 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.detail_overviewContent /deep/ul,
.detail_overviewContent /deep/ol
{
    margin: 0px !important;
}
.detail_overviewContent /deep/img {
    max-width: 100% !important;
    height: auto !important;
}
.detail_overviewContent /deep/ h4{
    font-size: 20px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}




.overviewContent{
    font-size: 16px;
}
.overviewContent /deep/a{
    color: #0070c0 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/span{
    font-size: 16px !important;
    /* color: #000 !important; */
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ h4 span {
    font-size: 22px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ h5 span, 
.overviewContent /deep/ h5  {
    font-weight: 700;
    color: #00B0F0 !important;
    padding-left: 5px;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    margin: 0px;
}
.overviewContent /deep/ span a {
    font-size: 16px !important;
}
.overviewContent /deep/ p span {
    font-size: 16px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ h5 span {
    font-size: 16px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ ul li span {
    font-size: 16px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ ol li span {
    font-size: 16px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ ol li a span {
    font-size: 16px !important;
    color: #0070c0 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.overviewContent /deep/ul,
.overviewContent /deep/ol
{
    margin: 0px !important;
}
.overviewContent /deep/img {
    max-width: 100% !important;
    height: auto !important;
}
.overviewContent /deep/ h4{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.moduleContent{
    line-height: 1.5;
}
.moduleContent /deep/.github-markdown-body{
    padding: 0px;
}
.moduleContent /deep/ img{
    margin: 0 auto;
    display: flex;
}
.QingContainer{
    width: 100%;
    text-align: left;
}
.title{
    font-size: 28px;
    color: #002c85;
    font-weight: 600;
    margin: 15px 0px;
    letter-spacing: 0px;
}
.p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
}
.lineupContent{
    display: flex;
    justify-content: center;
}

.moduleContent{
    color:#000;
    letter-spacing: 0px;
    margin-bottom:50px;
}
.moduleTitle{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 20px;
 }
.table_1 {
    /* display: flex;
    align-items: start;
    justify-content: space-between; */
    font-weight: 600;
    border-bottom: 2px solid #ccc;
}
.table_2 {
    /* display: flex;
    align-items: start;
    justify-content: space-between; */
    border-bottom: 1px solid #ccc;
}
.table_1 .tableTitle{
    /* width: 15%; */
    padding: 12px;
}
.table_1 .tableContent{
    /* width: 83%; */
    padding: 12px;
}
.table_2 .tableTitle{
    /* width: 15%; */
    /* padding: 12px; */
    padding-top: 8px;
    color: #337BC5 !important;
    cursor: pointer;
    word-break: break-all;
    text-decoration:none;
}
.table_2 .tableContent{
    /* width: 83%; */
    padding: 8px;
}
.buttonDiv{
    display: flex;
    justify-content: center;
}

.btn-danger{
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}
.btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}
.btn-primary{
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}
.btn-primary:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
}
.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}
.btn-info:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #269abc;
}
.el-breadcrumb__item{
    margin-bottom: 5px;
}

@media screen and (max-width: 688px) {
    .btn-danger {
        padding: 12px 10px;
    }
    .btn-primary {
        padding: 12px 10px;
    }
    .btn-info {
        padding: 12px 10px;
    }
}
</style>